// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./components/Hero";
import Work from "./components/Work";
import Projects from "./components/projects";
import styles from "./styles/App.module.css";

const App = () => {
  return (
    <Router>
      <div className={styles.app}>
        {/* The Navigation is now embedded within the Hero component */}
        <Routes>
          <Route path="/" element={<Hero />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
