// src/components/Hero.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Hero.module.css";
import logo from "../assets/Abra.png"; // Import the logo
import heroImage from "../assets/heroooo.png"; // Import the hero background image
import AboutSection from "./AboutSection"; // Import the AboutSection component
import ExpertiseSection from "./ExpertiseSection"; // Import the ExpertiseSection component
import PortfolioSection from "./PortfolioSection"; // Import the PortfolioSection component
import AwardsSection from "./AwardsSection";
import ContactSection from "./ContactSection";
import ScrollAnimation from "./scrollAnimations"; // Import the ScrollAnimation component

const OrientationWarning = () => {
    useEffect(() => {
      const handleOrientationWarning = () => {
        if (window.innerWidth < 768 && window.innerHeight > window.innerWidth) {
          alert("For a better experience, please rotate your device horizontally.");
        }
      };
      
      window.addEventListener("resize", handleOrientationWarning);
      handleOrientationWarning();
  
      return () => {
        window.removeEventListener("resize", handleOrientationWarning);
      };
    }, []);
  };
  

const Hero = () => {
  return (
    <>
      <OrientationWarning />
      {/* Hero Section */}
      <div className={styles.hero}>
        {/* Background Image */}
        <div
          className={styles.heroImage}
          style={{ backgroundImage: `url(${heroImage})` }}
        ></div>

        {/* Embedded Navigation */}
        <div className={styles.navContainer} style={{ position: "relative", zIndex: 10 }}>
          <nav className={styles.navigation}>
            {/* Logo */}
            <div className={styles.logo}>
              <img src={logo} alt="Logo" />
            </div>

            {/* Navigation Links */}
            <div className={styles.navLinks}>
              <Link to="/" className={styles.navLink}>
                Home
              </Link>
              <a href="https://www.linkedin.com/in/abraham-mora-tadeo" className={styles.navLink} target="_blank" rel="noopener noreferrer">
                Service
              </a>
              <a href="https://github.com/AbrahamM007" className={styles.navLink} target="_blank" rel="noopener noreferrer">
                Projects
              </a>
            </div>

            {/* Book a Call Button */}
            <div className={styles.navButton}>
              <a href="https://calendly.com/mora78606/30min" target="_blank" rel="noopener noreferrer">
                <button className={styles.bookButton}>
                  BOOK A CALL <span className={styles.buttonIcon}>➔</span>
                </button>
              </a>
            </div>
          </nav>
        </div>

        <ScrollAnimation>
          {/* Hero Content */}
          <div className={styles.heroContent}>
            <h1>Capturing beautiful moments with Design and Development</h1>
            <div className={styles.heroTags}>
              <button className={styles.tagButton}>Developer</button>
              <button className={styles.tagButton}>Designer</button>
              <button className={styles.tagButton}>Photographer</button>
              <button className={styles.tagButton}>Singer</button>
              <button className={styles.tagButton}>Entrepreneur</button>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      {/* About Section */}
      <ScrollAnimation>
        <AboutSection />
      </ScrollAnimation>

      {/* Expertise Section */}
      <ScrollAnimation>
        <ExpertiseSection />
      </ScrollAnimation>

      {/* Portfolio Section */}
      <ScrollAnimation>
        <PortfolioSection />
      </ScrollAnimation>

      {/* Awards Section */}
      <ScrollAnimation>
        <AwardsSection />
      </ScrollAnimation>

      {/* Contact Section */}
      <ScrollAnimation>
        <ContactSection />
      </ScrollAnimation>
    </>
  );
};

export default Hero;
