// src/components/ExpertiseSection.js
import React, { useState } from "react";
import styles from "../styles/ExpertiseSection.module.css";
import landscapeImage from "../assets/harvest.jpg"; // Image for Landscape category
import wildlifeImage from "../assets/website.jpg"; // Image for Wildlife category
import architecturalImage from "../assets/mobile.jpg"; // Image for Architectural category
import travelImage from "../assets/csap.jpg"; // Image for Travel category
import portraitImage from "../assets/harvestapp.jpg"; // Image for Portrait category
import ScrollAnimation from './scrollAnimations';

// src/components/ExpertiseSection.js
const ExpertiseSection = () => {
    // Define the categories and their related data
    const categories = [
      {
        name: "Web Design",
        image: landscapeImage,
        title: "UI/UX Design",
        subtitle: "Harvest",
      },
      {
        name: "Web Development",
        image: wildlifeImage,
        title: "Website Architecture",
        subtitle: "New Hope Church",
      },
      {
        name: "App Design",
        image: architecturalImage,
        title: "UI/UX Design",
        subtitle: "New Hope Church",
      },
      {
        name: "Branding",
        image: travelImage,
        title: "Brand Creation ",
        subtitle: "Computer Science Acheavement Program",
      },
      {
        name: "App Development",
        image: portraitImage,
        title: "Construction of mobile app",
        subtitle: "Harvest",
      },
    ];
  
    // Use state to track the current category
    const [currentCategory, setCurrentCategory] = useState(categories[0]);
  
    // Function to handle category click
    const handleCategoryClick = (category) => {
      setCurrentCategory(category);
    };
  
    return (
      <div className={styles.expertiseSection}>
        {/* Left Section: Section Number and Text */}
        <div className={styles.leftSection}>
          <span className={styles.sectionNumber}>(02)</span>
          <h2 className={styles.sectionTitle}>My Expertise</h2>
          <h1 className={styles.mainHeading}>
          Transforming  <br /> moments into living dreams, <br />  and crafting every vision into a beautiful reality
          </h1>
        </div>
  
        {/* Right Section: Category List */}
        <div className={styles.rightSection}>
          <ul className={styles.categoryList}>
            {categories.map((category) => (
              <li
                key={category.name}
                className={`${styles.categoryItem} ${
                  currentCategory.name === category.name ? styles.activeCategory : ""
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category.name}
              </li>
            ))}
          </ul>
        </div>
  
        {/* Carousel Section */}
        <div className={styles.carouselSection}>
          <div className={styles.carouselImageWrapper}>
            <img
              src={currentCategory.image}
              alt={currentCategory.title}
              className={styles.carouselImage}
            />
          </div>
          <div className={styles.carouselText}>
            <p className={styles.imageTitle}>{currentCategory.title}</p>
            <span className={styles.imageSubtitle}>{currentCategory.subtitle}</span>
          </div>
  
          {/* See All Button */}
        </div>
      </div>
    );
  };
  
  export default ExpertiseSection;
  