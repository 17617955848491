// src/components/ContactSection.js
import React from "react";
import styles from "../styles/ContactSection.module.css";
import ScrollAnimation from './scrollAnimations';

const ContactSection = () => {
  return (
    <div className={styles.contactSection}>
      <div className={styles.contactContent}>
        <div className={styles.contactText}>
          <span className={styles.sectionNumber}>(05) Contacts</span>
          <h2 className={styles.contactTitle}>
            Let’s <strong>Discuss</strong> Your Vision <br />
          </h2>
          <a href="https://calendly.com/mora78606/30min" target="_blank" rel="noopener noreferrer">
            <button className={styles.contactButton}>
              LET'S TALK <span className={styles.buttonArrow}>➔</span>
            </button>
          </a>
        </div>
      </div>

      <footer className={styles.footer}>
        <div className={styles.footerLinks}>
          <a href="https://github.com/AbrahamM007" className={styles.footerLink}>Projects</a>
          <a href="https://www.linkedin.com/in/abraham-mora-tadeo" className={styles.footerLink}>Services</a>
        </div>
      </footer>
    </div>
  );
};

export default ContactSection;
