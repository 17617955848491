// src/components/AwardsSection.js
import React, { useState } from 'react';
import styles from '../styles/AwardsSection.module.css';
import awardImage from '../assets/HarvestLogo.png'; // Replace with the appropriate path to your image
import awardImage2 from '../assets/Melon.png'; // Replace with the appropriate path to your image
import ScrollAnimation from './scrollAnimations';

const AwardsSection = () => {
  const [selectedAward, setSelectedAward] = useState({
    year: 2024,
    title: "SCLA Demo Day 1st Place",
    description:
      " E-commerce platform that connectshome farmers to consumers, providing fresh produce directly from the farm to the consumer's doorstep.",
    image: awardImage,
  });

  const awards = [
    {
      year: 2024,
      title: "SCLA Demo Day 1st Place",
      description:
        "E-commerce platform that connectshome farmers to consumers, providing fresh produce directly from the farm to the consumer's doorstep.",
      image: awardImage,
    },
    {
      year: 2023,
      title: "SCLA Demo Day 2nd Place",
      description:
        "Bike security system that uses GPS tracking and smart locks to prevent bike theft and aid in recovery.",
      image: awardImage2,
    },
  ];

  return (
    <div className={styles.awardsSection}>
      <div className={styles.leftSection}>
        <span className={styles.sectionNumber}>(04) Awards</span>
        <h2 className={styles.awardsTitle}>
        I don't strive for awards, but I am Honored to be recognized for my passion and dedication.
        </h2>
        <ul className={styles.awardsList}>
          {awards.map((award) => (
            <li
              key={award.year}
              className={`${styles.awardItem} ${
                selectedAward.year === award.year ? styles.activeAward : ""
              }`}
              onClick={() => setSelectedAward(award)}
            >
              <span className={styles.awardYear}>{award.year}</span>
              <span className={styles.awardTitle}>{award.title}</span>
              <span className={styles.arrowIcon}>➔</span>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.rightSection}>
        <img
          src={selectedAward.image}
          alt={selectedAward.title}
          className={styles.awardImage}
        />
        <h3 className={styles.selectedTitle}>{selectedAward.title}</h3>
        <p className={styles.selectedDescription}>
          {selectedAward.description}
        </p>
      </div>
    </div>
  );
};

export default AwardsSection;
