// src/components/ScrollAnimation.js
import React, { useEffect, useRef } from 'react';
import styles from '../styles/ScrollAnimation.module.css';

const ScrollAnimation = ({ children }) => {
    const sectionRef = useRef(null);

    useEffect(() => {
        const elements = sectionRef.current?.children;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.visible);
                        observer.unobserve(entry.target); // Stop observing after visible
                    }
                });
            },
            {
                threshold: 0.15, // Trigger when 15% of the element is visible
            }
        );

        if (elements) {
            Array.from(elements).forEach((element) => {
                observer.observe(element);
            });
        }

        return () => {
            if (elements) {
                Array.from(elements).forEach((element) => {
                    observer.unobserve(element);
                });
            }
        };
    }, []);

    return (
        <div ref={sectionRef} className={styles.section}>
            {children}
        </div>
    );
};

export default ScrollAnimation;
