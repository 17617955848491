// src/components/AboutSection.js
import React from "react";
import styles from "../styles/AboutSection.module.css";
import ScrollAnimation from './scrollAnimations';

const AboutSection = () => {
  return (
    <div className={styles.aboutSection}>
      {/* Left Section: Section Number and Title */}
      <div className={styles.aboutLeft}>
        <span className={styles.sectionNumber}>(01)</span>
        <h2 className={styles.sectionTitle}>About Me</h2>
        <p className={styles.sectionDescription}>
        Passionate UI/UX designer and full stack developer with a keen eye for detail and a love for elegant design. Dedicated to creating seamless user experiences and innovative solutions.
        </p>
      </div>

      {/* Right Section: Main Heading and Learn More Button */}
      <div className={styles.aboutRight}>
        <h1 className={styles.mainHeading}>
        Design and coding are fueled by a deep passion for <span className={styles.highlight}>bringing ideas to life</span> with <span className={styles.highlight}>creativity</span> and a touch of ingenuity
        </h1>
        <a href="https://www.linkedin.com/in/abraham-mora-tadeo" target="_blank" rel="noopener noreferrer">
          <button className={styles.learnMoreButton}>
            Learn More <span className={styles.arrowIcon}>➔</span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default AboutSection;
