// src/components/PortfolioSection.js
import React from "react";
import styles from "../styles/PortfolioSection.module.css";
import cheetahImage from "../assets/HarvestLogo.png"; // Import your images
import templeImage from "../assets/Melon.png";
import portraitImage from "../assets/NVIDIA.png";
import ScrollAnimation from './scrollAnimations';

const PortfolioSection = () => {
  return (
    <div className={styles.portfolioSection}>
      {/* Left Section: Header Text */}
      <div className={styles.leftSection}>
        <span className={styles.sectionNumber}>(03)</span>
        <h2 className={styles.sectionTitle}>My Work</h2>
        <h1 className={styles.mainHeading}>
          Explore the artistry and precision behind <span className={styles.highlight}>my portfolio</span> of high-end project
        </h1>
      </div>

      {/* Right Section: Description */}
      <div className={styles.rightSection}>
        <p className={styles.description}>
        I blend great design with innovative development to craft digital experiences that are both stunning and seamless, merging artistry with technology to bring your visions to life.
        </p>
        <a href="https://github.com/AbrahamM007" target="_blank" rel="noopener noreferrer">
        <button className={styles.exploreButton}>
          Explore More <span className={styles.arrowIcon}>➔</span>
        </button>
        </a>
      </div>

      {/* Portfolio Cards */}
      <div className={styles.cardsSection}>
        {/* Card 1 */}
        <div className={styles.card}>
          <img src={cheetahImage} alt="Majestic Creatures of the African Savanna" className={styles.cardImage} />
          <div className={styles.cardText}>
            <h3 className={styles.cardTitle}>Harvest</h3>
            <p className={styles.cardSubtitle}>Connecting local produce growers with buyers seamlessly</p>
            
          </div>
        </div>
        {/* Card 2 */}
        <div className={styles.card}>
          <img src={templeImage} alt="A Temple's Serene Silhouette" className={styles.cardImage} />
          <div className={styles.cardText}>
            <h3 className={styles.cardTitle}>Bike Security System</h3>
            <p className={styles.cardSubtitle}>Taking a slice out of crime</p>
            
          </div>
        </div>
        {/* Card 3 */}
        <div className={styles.card}>
          <img src={portraitImage} alt="Moments Framed in Portraits" className={styles.cardImage} />
          <div className={styles.cardText}>
            <h3 className={styles.cardTitle}>NVIDIA AI Models</h3>
            <p className={styles.cardSubtitle}>AI for those who need it most</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
